import type {Handle} from '@sveltejs/kit';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import {LeadNurturingWebSocketClient} from '$lead-nurturing/lead-nurturing-web-socket-client';
import {PUBLIC_REMEMBELL_STAGE} from '$env/static/public';

// todo: move to config
Sentry.init({
  dsn: 'https://7e36a9667bdbb2933abf408fdef30672@o4506959107260416.ingest.us.sentry.io/4508190524964864',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 0.001,
  integrations: [replayIntegration()],
  environment: PUBLIC_REMEMBELL_STAGE
});

Sentry.setTag("remembell.anonymous", true);

export const handle: Handle = async ({event, resolve}) => {
  // LeadNurturingWebSocketClient.connect(PUBLIC_REMEMBELL_LEAD_NURTURING_REALTIME_API_URL);
  return resolve(event);
};

export const handleError = handleErrorWithSentry();
